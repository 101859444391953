@mixin bebas-neue {
    font-family: 'Bebas Neue', sans-serif;
}

@mixin inter {
    font-family: 'Inter', sans-serif;
}

@mixin h1 {
    font-size: 3.5rem;
    line-height: 1em;
}

@mixin h2 {
    font-size: 2.5rem;
    line-height: 1em;
}

@mixin h3 {
    font-size: 2rem;
    line-height: 1em;
}

@mixin h4 { // p.big
    font-size: 1.5rem;
    line-height: 1em;
}

@mixin h5 { // p.big
    font-size: 1.25rem;
    line-height: 1em;
}

@mixin p {
    font-size: 1rem; // 1.25rem
    
}

@mixin smallp {
    font-size: 0.875rem;
}

@mixin section {
    padding: 7rem 0rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

@mixin layout {
    width: 100%;
    max-width: 1024px; //get from variable
    padding: 0 2rem; //get from variable
}