@use "../../styles/mixins";
@import "../../styles/colors";

.swarm-removal {
    background: $stb-bg-light;

    &__title {
        @include mixins.bebas-neue;
        text-align: center;
        padding-top: 16px;
        font-size: 56px;
        font-weight: 700;
        color: $stb-primary-light;
    }
    
    &__description {
        text-align: center;
        font-size: 18px;
        font-weight: 300;
        color: $stb-black;
        margin-bottom: 26px;
    }

    &__tab-list {
        justify-content: center;
        margin-bottom: 46px;

        .nav-item .nav-link {
            background: transparent;
            color: $stb-black;
            font-weight: 500;
            padding-right: 48px;
            padding-left: 48px;
            border-radius: 6px;

            &.active {
                background: $stb-secondary-light;
                color: $stb-white;
            }
        }
    }
}